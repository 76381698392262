<template>
    <div class="inquire-page">
        <div class="banner vivify fadeIn" v-if="bannerList.length>0">
            <img :src="bannerList[0].banner">
        </div>
        <div class="contant">
            <div class="contantBox">
                <div v-if="tableData.length>0">
                    <div class="titleBox d-n">
                        <div class="title">{{ $t('Inquire.main_number') }}</div>
                        <div class="title">{{ $t('Inquire.image') }}</div>
                        <div class="title">{{ $t('Inquire.size') }}</div>
                        <div class="title">{{ $t('Inquire.CarDealer') }}</div>
                        <div class="title">{{ $t('Inquire.NoteType') }}</div>
                        <div class="title">{{ $t('Inquire.ChineseName') }}</div>
                    </div>
                    <transition-group> <!-- 1️⃣使用 transition-group 标签包裹列表； -->
                        <div class="valueBox" v-for="(item,index) in tableData" :key="index">
                            <div class="box1 box2">
                                <div class="titleText ds-n">{{ $t('Inquire.main_number') }}</div>
                                <div class="text">{{ item.main_number }}</div>
                            </div>
                            <div class="box1">
                                <div class="titleText ds-n">{{ $t('Inquire.image') }}</div>
                                <el-image
                                    class="image"
                                    :src="item.master_image"
                                    :initial-index="index"
                                    :preview-src-list="[item.master_image]"
                                    :preview-teleported="true"
                                ></el-image>
                            </div>
                            <div class="box1 box3">
                                <div class="titleText ds-n">{{ $t('Inquire.size') }}</div>
                                <div>
                                    <div class="text2" v-for="(child,i) in item.size" :key="i">
                                        {{ child.field }}： {{ child.value }}
                                    </div>
                                </div>
                            </div>
                            <div class="box1 box3">
                                <div class="titleText ds-n">{{ $t('Inquire.CarDealer') }}</div>
                                <div>
                                    <div class="text" v-for="(child,i) in item.model" :key="i">
                                        {{ child.business_en }}
                                    </div>
                                </div>
                            </div>
                            <div class="box1 box3">
                                <div class="titleText ds-n">{{ $t('Inquire.NoteType') }}</div>
                                {{ item.remarks_model }}
                            </div>
                            <div class="box1 box2">
                                <div class="titleText ds-n">{{ $t('Inquire.ChineseName') }}</div>
                                <div class="text">{{ item.name }}</div>
                            </div>
                        </div>
                    </transition-group>
                </div>
                <div v-else style="margin: 100px auto;">
                    <el-empty description="暂无数据" :image="require('./img/wuImage.png')"></el-empty>
                </div>
                <el-pagination background @current-change="handleCurrentChange" :current-page.sync="form.page" :pager-count="7" :page-size="Number(searchData.limit)"
                               layout="prev, pager, next" :hide-on-single-page="true" :total="searchData.total_count">
                </el-pagination>
            </div>
            <div class="searchBox">
                <div class="titleBox">
                    <div class="line"></div>
                    <div class="text">{{ $t('Inquire.numberSearch') }}</div>
                </div>
                <div class="inputBox">
                    <el-input :placeholder="$t('menu.please')" v-model="form.number" @keyup.enter.native="searchClick"></el-input>
                    <div class="search" @click="searchClick">
                        <img src="./img/search.png">
                    </div>
                </div>
                <div class="titleBox">
                    <div class="line"></div>
                    <div class="text">{{ $t('Inquire.typeSearch') }}</div>
                </div>
                <div class="inputBox">
                    <el-input :placeholder="$t('menu.please')" v-model="form.model" @keyup.enter.native="searchClick"></el-input>
                    <div class="search" @click="searchClick">
                        <img src="./img/search.png">
                    </div>
                </div>
<!--                <div class="inputBox01">-->
<!--                    <el-select v-model="check_id" placeholder="请选择" style="width: 100%;"-->
<!--                               @change="checkChange($event)">-->
<!--                        <el-option v-for="item in checkArr" :key="item.id" :label="item.text"-->
<!--                                   :value="item.id"></el-option>-->
<!--                    </el-select>-->
<!--                    <el-select v-model="check_id" placeholder="请选择" style="width: 100%;"-->
<!--                               @change="checkChange($event)">-->
<!--                        <el-option v-for="item in checkArr" :key="item.id" :label="item.text"-->
<!--                                   :value="item.id"></el-option>-->
<!--                    </el-select>-->
<!--                </div>-->
                <div class="titleBox">
                    <div class="line"></div>
                    <div class="text">{{ $t('Inquire.sizeSearch') }}</div>
                </div>
                <div class="titleBox01">{{ $t('Inquire.wide') }}</div>
                <div class="inputBox02">
                    <el-input :placeholder="$t('menu.please')" v-model="form.wide" @keyup.enter.native="searchClick"></el-input>
                    <div class="line">-</div>
                    <el-input :placeholder="$t('menu.please')" v-model="form.wide_end" @keyup.enter.native="searchClick"></el-input>
                </div>
                <div class="titleBox01">{{ $t('Inquire.height') }}</div>
                <div class="inputBox02">
                    <el-input :placeholder="$t('menu.please')" v-model="form.high" @keyup.enter.native="searchClick"></el-input>
                    <div class="line">-</div>
                    <el-input :placeholder="$t('menu.please')" v-model="form.high_end" @keyup.enter.native="searchClick"></el-input>
                </div>
                <div class="titleBox01">{{ $t('Inquire.thickness') }}</div>
                <div class="inputBox02">
                    <el-input :placeholder="$t('menu.please')" v-model="form.thick" @keyup.enter.native="searchClick"></el-input>
                    <div class="line">-</div>
                    <el-input :placeholder="$t('menu.please')" v-model="form.thick_end" @keyup.enter.native="searchClick"></el-input>
                </div>
                <div class="tishi">
                    <img class="image1" src="./img/tishi.png">
                    {{ $t('Inquire.interval') }}
                </div>
                <div class="search01" @click="searchClick">
                    <img src="./img/search.png">
                </div>
<!--                <div class="titleBox">-->
<!--                    <div class="line"></div>-->
<!--                    <div class="text">按刹车蹄片尺寸查询</div>-->
<!--                </div>-->
<!--                <div class="titleBox01">宽度</div>-->
<!--                <div class="inputBox02">-->
<!--                    <el-input placeholder="请输入关键词"></el-input>-->
<!--                    <div class="line">-</div>-->
<!--                    <el-input placeholder="请输入关键词"></el-input>-->
<!--                </div>-->
<!--                <div class="titleBox01">直径</div>-->
<!--                <div class="inputBox02">-->
<!--                    <el-input placeholder="请输入关键词"></el-input>-->
<!--                    <div class="line">-</div>-->
<!--                    <el-input placeholder="请输入关键词"></el-input>-->
<!--                </div>-->
<!--                <div class="tishi">-->
<!--                    <img class="image1" src="./img/tishi.png">-->
<!--                    两个值的间隔不要大于5毫米-->
<!--                </div>-->
<!--                <div class="search01">-->
<!--                    <img src="./img/search.png">-->
<!--                </div>-->
            </div>

        </div>
    </div>
</template>
<script>
import '../assets/css/common.css'
import '@/views/scss/inquire.scss'

export default {
    name: "Inquire",
    data() {
        return {
            check_id: '',
            checkArr: [
                {
                    id: 1,
                    text: '乘用车刹车片'
                },
                {
                    id: 2,
                    text: '鼓式刹车蹄'
                },
                {
                    id: 3,
                    text: '商用车刹车片'
                },
            ],
            bannerList: [],
            searchData: [],
            tableData: [],
            form: {
                page: 1,
                limit: 5,
                is_page:1,
                number:'',
                model:'',
                wide:'',
                wide_end:'',
                high:'',
                high_end:'',
                thick:'',
                thick_end:''
            },
        }
    },
    created() {
        this.Interface()
    },
    mounted() {
        this.InterfaceList()
    },
    methods: {
        /**搜索按钮点击*/
        searchClick() {
            this.form.page = 1
            this.InterfaceList()
        },
        handleCurrentChange(val) {
            if (this.form.page) {
                this.form.page = val;
            }
            this.InterfaceList()
        },
        async InterfaceList() {
            //产品查询
            await this.$axios.post('pcapi/index/getGoods', {
                ...this.form
            }).then(res => {
                if (res.data.code == 1) {
                    this.searchData = res.data.data
                    this.tableData = res.data.data.list
                }
            })
        },
        async Interface() {
            //公司信息
            await this.$axios.post('pcapi/index/banner', {
                type: 5
            }).then(res => {
                if (res.data.code == 1) {
                    this.bannerList = res.data.data
                }
            })
        },
        /**
         * 检测人员选择
         * */
        checkChange(e) {
            this.check_id = e
        },
    }

}
</script>
<style>

</style>
